import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FROM_ID_CAMPAIGNS, SIGNUP_INTENT } from '../../constants';

import { selectOpenModalTypeIs } from '../../selectors/modals';

import Modal from './Modal';
import PreviewsModalLeftPane from './PreviewsModalLeftPane';
import PreviewsModalRightPane from './PreviewsModalRightPane';

import * as uiActions from '../../actions/ui';
import * as analyticsActions from '../../actions/analytics';

import { selectPageTrackingContext } from '../../selectors/client';

import styles from '../premium/ConversionFormVisual.css';
import { selectSubscriptionFromId } from '../../selectors/subscriptionOffer';

class PreviewsModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    analyticsTrack: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    pageTrackingContext: PropTypes.object,
    fromId: PropTypes.string.isRequired,
  };

  onComplete = () => {
    this.props.closeModal();
    if (!__ELECTRON__) {
      this.props.showModal('WELCOME_MODAL');
    }
  };

  onClose = () => {
    const { closeModal, analyticsTrack, pageTrackingContext } = this.props;
    closeModal();
    analyticsTrack('Closed Previews Modal', pageTrackingContext);
  };

  render() {
    const { isOpen, fromId } = this.props;
    return (
      <Modal isOpen={isOpen} onClose={this.onClose} contentLabel="subscribe-modal">
        <div className={styles.grid}>
          <PreviewsModalLeftPane />
          <PreviewsModalRightPane
            accountIntent={SIGNUP_INTENT}
            onComplete={this.onComplete}
            fromId={fromId}
          />
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const fromId = selectSubscriptionFromId(state);

  return {
    isOpen: selectOpenModalTypeIs(state, 'PREVIEWS_MODAL'),
    pageTrackingContext: selectPageTrackingContext(state),
    fromId:
      fromId !== FROM_ID_CAMPAIGNS.default.fromId ? fromId : FROM_ID_CAMPAIGNS.previewsModal.fromId,
  };
}

export default connect(mapStateToProps, {
  closeModal: uiActions.hideModal,
  showModal: uiActions.showModal,
  analyticsTrack: analyticsActions.track,
})(PreviewsModal);

export { PreviewsModal as PurePreviewsModal };
