// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router';
import classnames from 'classnames';
import Modal from './Modal';

import * as uiActions from '../../actions/ui';

import {
  selectPremiumExperiencePreviewsModal,
  selectOpenModalTypeIs,
} from '../../selectors/modals';
import { selectUserIsAuthenticated } from '../../selectors/user';
import { selectIsInApp } from '../../selectors/client';

import { Image } from '../util/Image';

import stylesFreeCollectionModal from './FreeCollectionStatusModal.css';
import styles from './PremiumExperiencePreviewsModal.css';

import { IMGIX_PARAMS } from '../../constants';

import { NavigationPlugin } from '../../capacitor-connector';

import AccountJoin from '../messages/AccountJoin';
import AccountLogin from '../messages/AccountLogin';
import SubscribeNow from '../messages/SubscribeNow';
import { selectUserCanAccessSubscribeView } from '../../selectors/subscription';

type OwnProps = {
  location: Object,
};

type MapStateToProps = {
  isOpen: boolean,
  isAuth: boolean,
  trigger: string,
  isInApp: boolean,
  imageUrl?: string,
  title?: string,
  subtitle?: string,
  plan?: string,
  userCanAccessSubscribeView: boolean,
};

type DispatchProps = {
  hideModal: Function,
  showModal: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

declare var __CAPACITOR__: boolean;

const PremiumExperiencePreviewsModal = ({
  isOpen,
  isAuth,
  hideModal,
  showModal,
  location,
  trigger,
  plan,
  imageUrl,
  title,
  subtitle,
  isInApp,
  userCanAccessSubscribeView,
}: Props) => {
  if (!userCanAccessSubscribeView) {
    return null;
  }

  const onSubscribeClick = e => {
    hideModal();

    if (isInApp && !__CAPACITOR__) {
      return;
    }

    e.preventDefault();

    if (__CAPACITOR__) {
      NavigationPlugin.androidShouldOpenSubscriptionPage();
      return;
    }

    showModal('SUBSCRIBE_MODAL', { trigger }, { plan });
  };

  const redirectUrl =
    `/${location.pathname}` +
    (location.search ? `${location.search}&showSubscribeModal=1` : '?showSubscribeModal=1');

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={hideModal}
      onClose={hideModal}
      contentLabel="premium-experience-previews-modal"
    >
      <section
        className={stylesFreeCollectionModal.component}
        data-test="premium-experience-previews-modal"
      >
        {imageUrl && (
          <Image
            noBase
            src={imageUrl}
            width={96}
            height={96}
            auto={IMGIX_PARAMS}
            className={styles.img}
            alt=""
          />
        )}
        {title && <h1 className="fz--gamma">{title}</h1>}
        {subtitle && <p className={stylesFreeCollectionModal.subTitle}>{subtitle}</p>}
        <div className={styles.btns}>
          {isAuth ? (
            <Link
              to={isInApp && !__CAPACITOR__ ? '/subscribe' : ''}
              className={classnames(styles.subNowBtn, 'c-btn c-btn--is-purple')}
              onClick={onSubscribeClick}
              data-test="premium-experience-previews-modal.sub-now-btn"
            >
              <SubscribeNow />
            </Link>
          ) : (
            <React.Fragment>
              <Link
                className="c-btn c-btn--is-purple"
                to={isInApp ? '/signup' : `/join?to=${encodeURIComponent(redirectUrl)}`}
                onClick={hideModal}
                data-test="premium-experience-previews-modal.signup-btn"
              >
                <AccountJoin />
              </Link>
              <Link
                className="c-btn c-btn--is-purple"
                to={isInApp ? '/login' : `/login?to=${encodeURIComponent(redirectUrl)}`}
                onClick={hideModal}
                data-test="premium-experience-previews-modal.login-btn"
              >
                <AccountLogin />
              </Link>
            </React.Fragment>
          )}
        </div>
      </section>
    </Modal>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  const { trigger, title, subtitle, imageUrl, plan } = selectPremiumExperiencePreviewsModal(state);
  return {
    isOpen: selectOpenModalTypeIs(state, 'PREMIUM_EXPERIENCE_PREVIEWS_MODAL'),
    isAuth: selectUserIsAuthenticated(state),
    trigger,
    title,
    subtitle,
    imageUrl,
    plan,
    isInApp: selectIsInApp(state),
    userCanAccessSubscribeView: selectUserCanAccessSubscribeView(state),
  };
}

const dispatchProps: DispatchProps = {
  hideModal: uiActions.hideModal,
  showModal: uiActions.showModal,
};

export default compose(
  withRouter,
  connect(mapStateToProps, dispatchProps)
)(PremiumExperiencePreviewsModal);
