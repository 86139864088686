// @flow
import type { Element } from 'react';
import { FormattedMessage } from 'react-intl';
import update from 'immutability-helper';
import invariant from 'invariant';
import { values, includes } from 'lodash';
import { LOCATION_CHANGE } from 'react-router-redux';

import filterAndSortParams from '../../utils/filterAndSortParams';
import {
  CHROME_LIGHT,
  CHROME_GLASS,
  CHROME_CLEAN_LINKS,
  CHROME_CLEAN,
  VALID_FACETS_PARAMS,
  ENTITY_TYPE_ALBUM,
  ENTITY_TYPE_PLAYLIST,
  DEFAULT_AUTOPLAY,
  // Payment Types
  VOUCHER_PAYMENT,
  CARD_PAYMENT,
  SEPA_PAYMENT,
  DEFAULT_THEME,
  PLAN_ID_PPC_12M,
  PLAN_ID_PPC_1M,
  PLAN_ID_PREMIUM,
  DEFAULT_PLAN_ID,
} from '../../constants';

import type { Theme, UIAction, PageTrackingContext } from '../../actions/ui';

import type { Album, Playlist, CourseResource } from '../../shapes/types';

const validChromes = [CHROME_LIGHT, CHROME_GLASS, CHROME_CLEAN_LINKS, CHROME_CLEAN];

export const DEFAULT_CHROME = CHROME_LIGHT;

const previewsModals = ['PREVIEWS_MODAL', 'EMBED_PREVIEWS_MODAL'];

const initialState = {
  chrome: DEFAULT_CHROME,
  autoplay: DEFAULT_AUTOPLAY,
  theme: DEFAULT_THEME,
  playerbarHidden: false,
  playerbarInfoHidden: false,
  playerAudioQualityDialogIsOpen: false,
  playerExternalDeviceDialogIsOpen: false,
  searchOverlayVisible: false,
  sharedUrl: null,
  sharedUrlParams: {},
  embedCode: null,
  sharedEntityType: null,
  defaultPaymentType: null,
  courseResource: null,
  bookletUrl: null,
  modalOpen: false,
  volumeIsVisible: false,
  facet: {},
  modalType: null,
  personalPlaylistModals: {
    addToPlaylistModal: {
      trackIds: [],
      trackingContext: {
        itemType: null,
        itemId: null,
      },
      origin: null,
    },
    editPlaylistModal: {
      playlist: {},
    },
    deletePlaylistModal: {
      playlist: {},
    },
  },
  previewsModal: {
    showingsNumber: 0,
  },
  pageTrackingContext: {
    contextType: null,
    contextId: null,
    contextSlug: null,
  },
  freeCollectionModal: null,
  premiumExperiencePreviewsModal: null,
  freeExperiencePreviewsModal: null,
  subscribeModal: null,
  facebookPasswordConfirmationModal: null,
};

export type State = {
  +chrome: string,
  +autoplay: boolean,
  +theme: Theme,
  +playerbarHidden: boolean,
  +playerbarInfoHidden: boolean,
  +searchOverlayVisible: boolean,
  +sharedUrl: ?string,
  +sharedUrlParams: ?Object,
  +modalOpen: boolean,
  +embedCode: ?string,
  +sharedEntityType: ?string,
  +defaultPaymentType: ?(VOUCHER_PAYMENT | CARD_PAYMENT | SEPA_PAYMENT),
  +courseResource: ?CourseResource,
  +bookletUrl: ?string,
  +volumeIsVisible: boolean,
  +facet: { +type?: string, +id?: string },
  +modalType: ?string,
  +personalPlaylistModals: Object,
  +previewsModal: { showingsNumber: number },
  +pageTrackingContext: PageTrackingContext,
  +freeCollectionModal: ?{|
    +collectionItem: Album | Playlist,
    +collectionItemType: ENTITY_TYPE_ALBUM | ENTITY_TYPE_PLAYLIST,
  |},
  +premiumExperiencePreviewsModal: ?{|
    +imageUrl?: string,
    +title?: Element<typeof FormattedMessage>,
    +subtitle?: Element<typeof FormattedMessage>,
    +trigger: string,
    +plan?: string,
  |},
  +freeExperiencePreviewsModal: ?{|
    +imageUrl?: string,
    +title?: Element<typeof FormattedMessage>,
    +subtitle?: Element<typeof FormattedMessage>,
    +trigger: string,
  |},
  +subscribeModal: ?{|
    +plan: ?(PLAN_ID_PPC_12M | PLAN_ID_PPC_1M | PLAN_ID_PREMIUM),
  |},
  +facebookPasswordConfirmationModal: ?{|
    +email: string,
    +confirmPassword: Function,
    +confirmCopy: Element<typeof FormattedMessage>,
  |},
};

export default function ui(state: State = initialState, action: UIAction): State {
  switch (action.type) {
    case 'SET_CHROME': {
      const chrome = action.chrome || DEFAULT_CHROME;

      invariant(
        includes(validChromes, chrome),
        `Tried to set chrome to an unsupported value: ${chrome}`
      );

      return update(state, {
        chrome: { $set: chrome },
      });
    }
    case 'SET_AUTOPLAY': {
      const autoplay = action.autoplay;
      return update(state, {
        autoplay: { $set: autoplay },
      });
    }
    case 'SET_THEME': {
      const theme = action.theme;
      return update(state, {
        theme: { $set: theme },
      });
    }
    case 'SEARCH_OVERLAY_SHOW':
      return update(state, {
        searchOverlayVisible: { $set: true },
      });
    case 'SEARCH_OVERLAY_HIDE':
      return update(state, {
        searchOverlayVisible: { $set: false },
      });
    case 'PLAYERBAR_HIDE':
      return update(state, {
        playerbarHidden: { $set: true },
      });
    case 'PLAYERBAR_SHOW':
    case 'PLAYER_PLAY':
      return update(state, {
        playerbarHidden: { $set: false },
      });
    case 'PLAYERBAR_INFO_SHOW':
      return update(state, {
        playerbarInfoHidden: { $set: false },
      });
    case 'PLAYERBAR_INFO_HIDE':
      return update(state, {
        playerbarInfoHidden: { $set: true },
      });
    case LOCATION_CHANGE:
      return update(state, {
        searchOverlayVisible: { $set: false },
      });
    case 'SHOW_VOLUME':
      return update(state, {
        volumeIsVisible: { $set: true },
      });
    case 'HIDE_VOLUME':
      return update(state, {
        volumeIsVisible: { $set: false },
      });
    case 'SHOW_SHORTCUTS_HELP':
      return update(state, {
        shortcutsHelpIsVisible: { $set: true },
      });
    case 'HIDE_SHORTCUTS_HELP':
      return update(state, {
        shortcutsHelpIsVisible: { $set: false },
      });
    case 'SHOW_FACET_GROUP_ALL_FACETS_MODAL':
      return update(state, {
        facet: {
          $set: {
            type: action.facetType,
            id: [
              action.facetType,
              ...values(filterAndSortParams(action.params, VALID_FACETS_PARAMS)),
            ].join('-'),
          },
        },
      });
    case 'HIDE_FACET_GROUP_ALL_FACETS_MODAL':
      return update(state, {
        facet: { $set: null },
      });
    case 'SHOW_MODAL':
      return update(state, {
        modalType: { $set: action.modalType },
        modalOpen: { $set: true },
        // Modal-specific flags
        sharedUrl: { $set: action.data ? action.data.sharedUrl : null },
        sharedUrlParams: { $set: action.data ? action.data.sharedUrlParams : null },
        embedCode: { $set: action.data ? action.data.embedCode : null },
        sharedEntityType: { $set: action.data ? action.data.entityType : null },
        defaultPaymentType: { $set: action.data ? action.data.defaultPaymentType : null },
        courseResource: { $set: action.data ? action.data.courseResource : null },
        bookletUrl: { $set: action.data ? action.data.bookletUrl : null },
        // End of modal-specific flags
        trackingContext: {
          $set: action.trackingContext ? action.trackingContext : null,
        },
        previewsModal: {
          showingsNumber: {
            $set: previewsModals.includes(action.modalType)
              ? state.previewsModal.showingsNumber + 1
              : state.previewsModal.showingsNumber,
          },
        },
        freeCollectionModal: {
          $set: action.data
            ? {
                ...action.data,
              }
            : null,
        },
        premiumExperiencePreviewsModal: {
          $set: action.data
            ? {
                ...action.data,
              }
            : null,
        },
        freeExperiencePreviewsModal: {
          $set: action.data
            ? {
                ...action.data,
              }
            : null,
        },
        subscribeModal: {
          $set: action.data
            ? {
                ...action.data,
                plan: action.data.plan || DEFAULT_PLAN_ID,
              }
            : { plan: DEFAULT_PLAN_ID },
        },
        facebookPasswordConfirmationModal: {
          $set: action.data
            ? {
                ...action.data,
              }
            : null,
        },
      });
    case 'HIDE_MODAL':
      if (
        action.options &&
        action.options.ignore &&
        action.options.ignore.includes(state.modalType)
      ) {
        return state;
      }

      return update(state, {
        modalType: { $set: null },
        modalOpen: { $set: false },
        sharedUrl: { $set: '' },
        embedCode: { $set: '' },
        sharedEntityType: { $set: null },
        courseResource: { $set: null },
        bookletUrl: { $set: null },
        subscribeModal: {
          $set: { plan: DEFAULT_PLAN_ID },
        },
      });
    case 'SET_ADD_TO_PLAYLIST_MODAL_STATE':
      return update(state, {
        personalPlaylistModals: {
          addToPlaylistModal: {
            $set: {
              trackIds: action.trackIds,
              trackingContext: action.trackingContext,
              origin: action.origin,
            },
          },
        },
      });
    case 'SET_EDIT_PLAYLIST_MODAL_STATE':
      return update(state, {
        personalPlaylistModals: {
          editPlaylistModal: {
            $set: {
              playlist: action.playlist,
            },
          },
        },
      });
    case 'SET_DELETE_PLAYLIST_MODAL_STATE':
      return update(state, {
        personalPlaylistModals: {
          deletePlaylistModal: {
            $set: {
              playlist: action.playlist,
            },
          },
        },
      });
    case 'CLEAR_PERSONAL_PLAYLIST_MODAL_STATES':
      return update(state, {
        personalPlaylistModals: {
          addToPlaylistModal: { $set: { trackIds: [], origin: null } },
          editPlaylistModal: { $set: { playlist: {} } },
          deletePlaylistModal: { $set: { playlist: {} } },
        },
      });
    case 'OPEN_PLAYER_AUDIO_QUALITY_DIALOG':
      return update(state, {
        playerAudioQualityDialogIsOpen: { $set: true },
      });
    case 'CLOSE_PLAYER_AUDIO_QUALITY_DIALOG':
      return update(state, {
        playerAudioQualityDialogIsOpen: { $set: false },
      });
    case 'OPEN_PLAYER_EXTERNAL_DEVICE_DIALOG':
      return update(state, {
        playerExternalDeviceDialogIsOpen: { $set: true },
      });
    case 'CLOSE_PLAYER_EXTERNAL_DEVICE_DIALOG':
      return update(state, {
        playerExternalDeviceDialogIsOpen: { $set: false },
      });
    case 'SET_PAGE_TRACKING_CONTEXT':
      return update(state, {
        pageTrackingContext: { $set: action.context },
      });
    default:
      (action: empty);
      return state;
  }
}
