// @flow
import React from 'react';
import type { Element } from 'react';
import { connect } from 'react-redux';
import Modal from './Modal';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';

import * as uiActions from '../../actions/ui';

import { selectFreeExperiencePreviewsModal, selectOpenModalTypeIs } from '../../selectors/modals';
import { selectIsInApp } from '../../selectors/client';
import { selectUserIsAuthenticated } from '../../selectors/user';

import { Image } from '../util/Image';

import stylesFreeCollectionModal from './FreeCollectionStatusModal.css';
import styles from './PremiumExperiencePreviewsModal.css';
import AccountJoin from '../messages/AccountJoin';
import AccountLogin from '../messages/AccountLogin';

import { IMGIX_PARAMS } from '../../constants';

type OwnProps = {};

type MapStateToProps = {
  isOpen: boolean,
  imageUrl: string,
  title?: Element<typeof FormattedMessage>,
  subtitle?: Element<typeof FormattedMessage>,
  trigger: string,
  isInApp: boolean,
  isAuth: boolean,
};

type DispatchProps = {
  hideModal: Function,
  showModal: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

declare var __CAPACITOR__: boolean;

const FreeExperiencePreviewsModal = ({
  isOpen,
  hideModal,
  showModal,
  imageUrl,
  trigger,
  title,
  subtitle,
  isInApp,
  isAuth,
}: Props) => {
  if (isAuth) {
    return null;
  }

  const onClickSignup = e => {
    hideModal();

    if (isInApp && !__CAPACITOR__) {
      return;
    }

    e.preventDefault();
    showModal('SIGNUP_MODAL', { trigger });
  };

  const onClickLogin = e => {
    hideModal();

    if (isInApp && !__CAPACITOR__) {
      return;
    }

    e.preventDefault();
    showModal('LOGIN_MODAL', { trigger });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={hideModal}
      onClose={hideModal}
      contentLabel="free-experience-previews-modal"
    >
      <section
        className={stylesFreeCollectionModal.component}
        data-test="free-experience-previews-modal"
      >
        {imageUrl && (
          <Image
            noBase
            src={imageUrl}
            width={96}
            height={96}
            auto={IMGIX_PARAMS}
            className={styles.img}
            alt=""
          />
        )}
        {title && <h1 className="fz--gamma">{title}</h1>}
        {subtitle && <p className={stylesFreeCollectionModal.subTitle}>{subtitle}</p>}
        <div className={styles.btns}>
          <Link
            to={isInApp && !__CAPACITOR__ ? '/signup' : ''}
            className="c-btn c-btn--is-purple"
            onClick={onClickSignup}
            data-test="free-experience-previews-modal.signup-btn"
          >
            <AccountJoin />
          </Link>
          <Link
            to={isInApp && !__CAPACITOR__ ? '/login' : ''}
            className="c-btn c-btn--is-purple"
            onClick={onClickLogin}
            data-test="free-experience-previews-modal.login-btn"
          >
            <AccountLogin />
          </Link>
        </div>
      </section>
    </Modal>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  const { imageUrl, title, subtitle, trigger } = selectFreeExperiencePreviewsModal(state);
  return {
    isOpen: selectOpenModalTypeIs(state, 'FREE_EXPERIENCE_PREVIEWS_MODAL'),
    imageUrl,
    title,
    subtitle,
    trigger,
    isInApp: selectIsInApp(state),
    isAuth: selectUserIsAuthenticated(state),
  };
}

const dispatchProps: DispatchProps = {
  hideModal: uiActions.hideModal,
  showModal: uiActions.showModal,
};

export default connect(mapStateToProps, dispatchProps)(FreeExperiencePreviewsModal);
